import styled from "styled-components";

export const Container = styled.div`
    padding: 90px 0;
    modal{
        z-index:10000;
        
    }
`;
export const FormContent = styled.div`
  display: flex;
  align-items: stretch;

  .error {
    color:red;
  }

  p.message-recieved {
    text-align: center;
    color: white;
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }

  .form {
    width: 100%;
    background-color: var(--darkBlue);

    form {
      width: 80%;
      margin: 50px auto;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        width: 100%;

        label {
          color: white;
          font-weight: bold;
          font-size: 0.9rem;
        }

        input,
        textarea {
          padding: 10px 6px;
          background: none;
          border: 1px solid white;
          border-radius: 5px;
          width: 100%;
          color: white;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: white;
            opacity: 80%;
          }
        }
      }

      .submit {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        @media (max-width: 576px) {
          justify-content: center;

          input {
            width: 100%;
          }
        }

        input {
          background-color: var(--lightGreen);
          padding: 10px 30px;
          color: white;
          border: none;
          border-radius: 5px;
        }
      }
    }

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .map {
    width: 44% !important;
    height: 783px !important;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`

export const Overview = styled.div`
    h4{
        font-weight:bold;
        color: black;
    }
    p{
        font-size: 0.95rem;
    }
`;

export const MediaContent = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:20px;

    .video-container{
        position: relative;
        border-radius: 14px;
        width:47%;

        @media (max-width:768px){
            width: 100%;
        }
        
        video{
            border-radius: 14px;
            margin:auto 0;
            width: 100%;
        }

        .play{
            height:50px;
            width:50px;
            position: absolute;
            left: 50%;
            margin-left: -25px;
            top: 50%;
            margin-top: -25px;
        }
    }

    .img{
        width: 47%;

        @media (max-width:768px){
            width: 100%;
        }

        .listing-image{
            width: 100%;
            border-radius:14px;
        } 
    }
`;

export const PropertyDetails = styled.div`
    padding: 30px 0;
    h4{
        color: black;
        font-weight:bold;
        font-size: 1.7rem;
        margin-bottom: 20px;
    }
`;

export const Spec = styled.div`
    .label{
        color: var(--lightGreen);
        margin-bottom: 2px;
        font-size: 0.9rem;
    }
    .value{
        font-weight: bold;
    }
`;

export const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
`;

export const UnitSpec = styled.div`
    display: flex;
    align-items: center;
    gap:15px;
    margin-bottom: 10px;

    span{
        font-weight: bold;
    }
`;

export const Share = styled.div`
    display:flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;

    .text{
        font-weight: bold;
    }

    .socials{
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;