import React from "react"
import { NotFoundContainer } from "./General.styles"

const UserNotFound = () => {
  return (
    <NotFoundContainer>
      <h3>It seems like this user has not been found!</h3>
    </NotFoundContainer>
  )
}

export default UserNotFound
