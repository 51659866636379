import styled from "styled-components"

export const NotFoundContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    width: 80%;

    h4 {
      font-size: 2rem;
      color: black;
    }
  }
`
