import React, { useEffect, useState, useContext } from "react"
import EventHero from "../../components/Hero/NewsEventHero"
import { StateContext } from "../../helpers/StateProvider"

import EventContent from "../../components/EventNews"
import Seo from "../../components/seo"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocument } from "../../hooks/useDocument"
import { doc, getDoc } from "firebase/firestore"
import { db } from "../../firebase/config"
import UserNotFound from "../../components/General/userNotFound"
import { graphql } from "gatsby"
import { Share } from "../../components/LisitngDetails/ListingDetails.styles"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share"
import Faceboook from "../../assets/icons/facebook-share.svg"
import Twitter from "../../assets/icons/twitter-share.svg"
import Linkedin from "../../assets/icons/linkedin-share.svg"
import Whatsapp from "../../assets/icons/whatsapp-icon.svg"
import telegram from "../../assets/icons/telegram_logo_circle_icon.svg"
import email from "../../assets/icons/email-envelop.svg"
const EventDetail = ({ params }) => {
  const currentUrl = window.location.href
  const [eventData, setEventData] = useState(null)
  const [authorData, setAuthorData] = useState(null)
  const [arabic, setArabic] = useState(false)
  const eventId = params.id
  const { language, setLanguage } = useContext(StateContext)

  useDocument("event", eventId).then(document => {
    if (document.data) {
      setEventData(document.data)
    }
  })

  useEffect(() => {
    if (eventData) {
      getDoc(doc(db, "users", eventData.added_by)).then(data => {
        setAuthorData(data.data())
      })
    }
  }, [eventData])

  const { authIsReady } = useAuthContext()
  return (
    <>
      {authIsReady && eventData && authorData && (
        <>
          <>
            <Seo title={`Event Detail - ${eventData.name}`} />
            <EventHero
              title={eventData.name}
              date={eventData.date}
              back={"EVENT LIST"}
              thumbnail={eventData.thumbnail.url}
              backLink={"/events"}
              city={eventData.city}
              country={eventData.country}
              added={authorData.first_name + " " + authorData.last_name}
            />

            <EventContent
              data={eventData}
              eventId={eventId}
              authorData={authorData}
            />
          </>
        </>
      )}
      {authIsReady && !authorData && (
        <>
          <>
            <Seo title={`Not found`} />
            <UserNotFound />
          </>
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
  }
`

export default EventDetail
